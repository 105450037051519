import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

// import Image from "./image"

const Header = ({ siteTitle }) => {
  const [showMenu, setShowMenu] = useState(false)
  const navbarCollapseClass = "navbar-collapse collapse"
  const showClass = !!showMenu ? " show" : ""

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
      id="sideNav"
    >
      <h1 className="h6">
        <Link to="/" className="navbar-brand flex-column">
          <>
            <span className="d-block mb-lg-3">{siteTitle}</span>
            <span className="d-none d-lg-block">
              <img
                alt="Leo Caseiro"
                src="https://res.cloudinary.com/leocaseiro/image/upload/v1581221240/xdmq9q4wgucs6bfycv1i.jpg"
                className="img-fluid img-profile rounded-circle mx-auto mb-2"
              />
            </span>
          </>
        </Link>
      </h1>
      <button
        onClick={() => setShowMenu(!showMenu)}
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded={showMenu}
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div
        className={`${navbarCollapseClass}${showClass}`}
        id="navbarSupportedContent"
      >
        <ul className="navbar-nav">
          <li className="nav-item">
            <Link className="nav-link" to="/">
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/skills">
              Skills
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/speaking">
              Speaking
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/projects">
              Projects
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/contact">
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
